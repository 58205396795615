<template>
  <div class="walletindex">
    <div class="top flex">
      <div class="topleft">
        <p class="count">可用余额</p>
        <p class="money"><span>￥</span>{{ money }}</p>
      </div>
      <!-- v-if="money!=0" -->
      <div class="topright" @click="totixian"  v-if="money>0">
        <span>去提现 </span>
      </div>
    </div>

    <!-- 钱包明细 -->
    <div class="walletdetail">
      <div class="title flexbox"> <span></span> 钱包明细</div>
    
    <el-table
      class="tablebox"
      :data="tableData"
      :header-cell-style="rowClass"
      :cell-style="cellStyle"
      style="width: 100%"
    >
      <el-table-column prop="createTime" label="时间"> </el-table-column>
      <el-table-column prop="content" label="收支项目"> </el-table-column>
      <el-table-column label="金额">
        <template slot-scope="scope">
          <div class="redcolor">
            <span :class="[scope.row.type==0 ? 'red' : '']">{{scope.row.type==0?'+':'-'}} ¥{{ scope.row.money }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="余额">
        <template slot-scope="scope">
          <div class="maincolor">
            <span>¥{{ scope.row.afterMoney }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- 暂无数据 -->
      <div slot="empty" class="emptybox">
        <img src="@/assets/img/empty.png" alt="" />
      </div>
    </el-table>
  </div>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  components: {},
  data() {
    return {
      money: '0', // 可用余额
      rowClass: {
        background: '#F8F8F8',
        color: '#666',
        fontWeight: 500,
        fontSize: '18px',
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '20px'
      },
      pagination: {
        size: 10, // 每页几条
        currentPage: 1,
        total: 0
      },
      tableData: []
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    // 去提现
    totixian() {
      this.$router.push({
        path: '/tixian'
      })
    },
    // 用户信息钱包明细
    getData() {
        let data = {
          userId: localStorage.getItem('userId')
          // pageSize: this.pagination.size,
          // pageNum: this.pagination.currentPage
        }
        apiUrl.lsUsermoneylog(data).then((res) => {
          if (res.code == 0) {
            this.money = res.money;
            this.tableData=res.list;
          } 
        })
			},
  },
  
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {}
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.walletindex {
  // padding: 0 33px 33px 33px;
  background-color: #f8f8f8;
  .top {
    height: 200px;
    background: url('../../assets/img/bg17.png') no-repeat center;
    background-size: 100% 100%;
    color: #fff;
    padding: 40px 40px 57px 64px;
    justify-content: space-between;
    .topleft {
      .count {
        font-size: 18px;
        font-weight: 400;
        color: #333;
        line-height: 20px;
        opacity: 0.7;
      }
      .money {
        font-size: 36px;
        font-weight: 520;
        color: #333;
        margin-top: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        span {
          font-size: 20px;
          margin-right: 5px;
          font-weight: 520;
        }
      }
    }
    .topright {
      width: 128px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      border-radius: 25px;
      background: rgba(256, 256, 256);
      cursor: pointer;
      span {
        font-size: 18px;
        color: #ff7500;
      }
    }
  }
  .walletdetail{
    width: 1216px;
    background: #FFFFFF;
    border-radius: 13px;
    margin-top: 24px;
    padding: 33px;
 
    .title{
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
      span{
        width: 8px;
        height: 20px;
        background: #305BFE;
        border-radius: 3px;
        margin-right: 16px;
      }
    }
  }
  .red{
    color: #F34933;
  }
}
</style>